<template>
  <div class="home">
    <div class="flex">
      <div class="item">
        <cloumn-item class="item-box box04" :title="`商圈各时间段人数情况：`">
          <JingQuRenShu />
        </cloumn-item>
        <cloumn-item class="item-box box03" :title="`商圈近日人数概况：`">
          <XinZengRenShu />
        </cloumn-item>
        <cloumn-item class="item-box box01" :title="`各点位半小时内实时进出流量：`">
          <realTime></realTime>
        </cloumn-item>
      </div>
      <div class="map">
        <cloumn-item class="item-box box07">
          <model></model>
        </cloumn-item>        
      </div>
      <div class="item">
        <cloumn-item class="item-box box02" :title="`商圈男女数量比例: `">
          <ShuLiangBiLi />
        </cloumn-item>
        <cloumn-item class="item-box box05" :title="`来源省份情况: `">
          <Provinces />
        </cloumn-item>
        <cloumn-item class="item-box box05" :title="`来源城市情况: `">
          <Laiyuandi />
        </cloumn-item>
        <cloumn-item class="item-box box06" :title="`各年龄段人数占比分析: `">
          <NianLingFenXi />
        </cloumn-item>
      </div>
    </div>
  </div>
</template>

<script>
import cloumnItem from "@/components/home/cloumnItem";
import JingQuRenShu from "@/components/renLiuFenXi/jingQuRenShu.vue";
import Laiyuandi from "@/components/renLiuFenXi/laiyuandi.vue";
import NianLingFenXi from "@/components/renLiuFenXi/nianLingFenXi.vue";
// import RenLiuReLiTu from "@/components/renLiuFenXi/renLiuReLiTu.vue";
import XinZengRenShu from "@/components/renLiuFenXi/xinZengRenShu.vue";
import ShuLiangBiLi from "@/components/renLiuFenXi/shuLiangBiLi.vue";
import realTime from "@/components/renLiuFenXi/realTime.vue";
import Provinces from "@/components/renLiuFenXi/provinces.vue";
import Model from "@/components/model.vue";

export default {
  // name: "Home",
  data() {
    return {
    };
  },
  components: {
    cloumnItem,
    JingQuRenShu,
    Laiyuandi,
    NianLingFenXi,
    XinZengRenShu,
    ShuLiangBiLi,
    realTime,
    Provinces,
    Model
  },
  methods: {
  },
  mounted() {
  },
  beforeDestroy() {
  },
};
</script>

<style scoped>
/**
 * The default size is 600px×400px, for responsive charts
 * you may need to set percentage values as follows (also
 * don't forget to provide a size for the container).
 */
/* .echarts {
  width: 100%;
  height: 100%;
} */
.canvas {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 99%;
}
.flex {
  align-items: start;
  justify-content: space-between;
}

.item {
}

.item-box {
  margin-bottom: 15px;
  width: 450px;
}

.item-box.box01 {
  /* height: 400px; */
}
.item-box.box04 {
  width: 450px;
}
.item2 .item-box:first-child {
  margin-right: 70px;
}
.box02 {
  height: 130px;
}
.box01 {
  padding: 0;
  background: none;
}
.box01 ::v-deep .top-left,
.box01 ::v-deep .bottom-left,
.box01 ::v-deep .top-right,
.box01 ::v-deep .bottom-right {
  display: none;
}
.box03 {
}
.box04 {
  background: none;
}
.box05 {
  height: 180px;
}
.box06 {
  height: 314px;
}
.box07 {
  width: 900px;
  height: 850px;
}
.weather{
  padding: 30px 0 30px 20px;
  margin-left: 40px;
}
</style>
